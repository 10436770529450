import React from "react";

const MultipleEnquiry = ({checkboxValues,handleCheckboxChange}) => {


  return (
    <div>
      <div className="lg:border-l border-zinc-100 mt-4">
        <h1 className="text-xl dark:text-zinc-700 text-center homepageheads font-semibold tracking-tight">
          Multiple Enquiry ?
        </h1>
        <div className="grid p-6 lg:grid-cols-1 gap-2">
          {Object.keys(checkboxValues).map((checkboxName) => (
            <label
              key={checkboxName}
              htmlFor={checkboxName}
              className="flex p-2 cursor-pointer w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:border-slate-300"
            >
              <input
                type="checkbox"
                id={checkboxName}
                className="checkbox border-zinc-300 checkbox-primary"
                checked={checkboxValues[checkboxName]}
                onChange={() => handleCheckboxChange(checkboxName)}
              />
              <span className="lg:text-lg text-md text-gray-500 ms-3 dark:text-gray-500">
                {checkboxName.charAt(0).toUpperCase() + checkboxName.slice(1)}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultipleEnquiry;
