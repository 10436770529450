export const DashboardDetails = {
  "Designer Jewellery": {
    title: "Designer Jewellery",
    description: `Elevate your style with Export India's curated collection, fusing uncut diamonds and affordable luxury for a touch of sophistication in every moment. Discover handcrafted treasures that redefine opulence.
        `,
    imageUrl: "/CollectionImages/Jewellery/jewellerycover.png",
  },
  "Metalcraft": {
    title: "Metalcraft",
    description: `Discover a millennia-spanning legacy in our collection of 80+ authentic metal crafts, sourced from artisans across the Indian sub-continent. Unveil the beauty of some of the world's oldest handicrafts with our exquisite pieces.
    `,
    imageUrl: "/CollectionImages/Metal/artmetalcover.png",
  },
  "Incense Sticks": {
    title: "Incense Sticks",
    description: `Export India presents artisan-crafted incense sticks, blending premium herbal ingredients with captivating scents like sandalwood and rose. Experience sensory delight with visually appealing offerings, showcasing traditional craftsmanship.`,
    imageUrl: "/CollectionImages/Incense/cover.jpg",
  },
  "Canecraft": {
    title: "Canecraft",
    description: `Discover Export India's canecraft collection, a fusion of functional elegance and traditional craftsmanship. Artisan-crafted with premium materials, these visually appealing pieces add sophistication to your space.
    `,
    imageUrl: "/CollectionImages/Cane/cover.jpg",
  },
  "Textile": {
    title: "Textile",
    description: `Experience sustainable luxury at Export India with textile pieces crafted from bamboo, jute, and cotton. Elevate your lifestyle with these eco-conscious creations, where every thread weaves a story of ethical elegance.`,
    imageUrl: "/CollectionImages/Textile/cover.jpg",
  },
  "Woodcraft": {
    title: "Woodcraft",
    description: `Discover the artistry of Indian culture with Export India's exquisite woodcraft, blending tradition and craftsmanship in timeless creations that elevate your living spaces.`,
    imageUrl: "/CollectionImages/Wood/woodcover.jpg",
  },
  // edit below details
  "Spiritual Sculptures": {
    title: "Spiritual Sculptures",
    description: `Dive into the divine ambiance of Export India's collection, featuring meticulously crafted sculptures of revered gods. Elevate your space with timeless artistry that resonates with the beauty of spirituality and heritage.`,
    imageUrl: "/CollectionImages/Spirscul/cover.jpg",
  },
  "Needlework": {
    title: "Needlework",
    description: `Discover Export India's artful needlework, intricately crafted through quilting, sewing, and crocheting. Elevate your space with timeless beauty, transforming it into a haven of creativity and comfort.`,
    imageUrl: "/CollectionImages/Needle/cover.jpg",
  },
  "Exquisite Furniture": {
    title: "Exquisite Furniture",
    description: `Infuse elegance into your space with Export India's meticulously crafted furniture, merging tradition with modern design. Transform your home into a haven of comfort and sophistication.`,
    imageUrl: "/CollectionImages/Furniture/cover.jpg",
  },
  
};
