import React from "react";
import AOSComponent from "../Utils/AosAnimation";

const WhoWeAre = () => {
  const heroStyles = {
    backgroundImage: "url(aboutus.png)", // Replace with the actual path to your image
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <AOSComponent animationType="fade-up" duration={800} easing="ease-in-out" once={false}>

    <div className="pb-16 lg:py-32">
      <div className="container m-auto text-gray-600 dark:text-gray-600 md:px-12 xl:px-6">
        <div className="mx-auto md:mb-12 lg:mb-8 max-w-3xl text-center">
          <h2 className="text-3xl homepageheads tracking-tight font-bold text-[#C69774] md:text-5xl">
            About us
          </h2>
          <div className="flex justify-center mt-6">
            <p className="bg-[#F1E4C3] rounded-3xl w-40 h-1 "></p>
          </div>
        </div>
        <div className="swiper mt-16 mySwiper">
          <div className="swiper-wrapper pb-8">
            <div className="swiper-slide !bg-transparent px-6 md:px-0">
              <div className="p-2 border border-gray-100  rounded-3xl bg-white dark:bg-white shadow-2xl shadow-gray-600/10  md:mx-auto lg:w-10/12 xl:w-8/12">
                <div className="grid grid-cols-1 md:grid-cols-5">
                  <img
                    src="https://i.pinimg.com/564x/06/b2/d5/06b2d559220d80a74fde887d0e4306f2.jpg"
                    className="md:col-span-2 h-80 w-full lg:h-96 lg:w-full md:w-full object-cover rounded-2xl"
                    alt="image"
                    loading="lazy"
                  />
                  <div className="md:col-span-3 flex flex-wrap items-center mx-auto space-y-6 p-6 text-justify sm:p-8">
                    <p>
                      <span className="lg:text-lg tracking-tight" > <span className="text-lg lg:text-2xl homepageheads font-bold">Export India</span> is an
                      organization focused on the promotion and motivation of
                      Indian handicrafts across the globe. Export India has a
                      family of thousands of artisans across India. Against an
                      inexorably commercialized and machine-made world, it
                      offers them the commitment of an existence where
                      livelihoods are guaranteed, customary abilities are
                      esteemed and where handmade items get the regard they
                      deserve.
                     </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-pagination"></div>
        </div>
      </div>
    </div>
    </AOSComponent>
  );
};

export default WhoWeAre;
