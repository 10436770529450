import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Pages/Loading";
import { useQuery } from "react-query";
import AOSComponent from "../Utils/AosAnimation";
import Glider from "react-glider";
import "glider-js/glider.min.css";

const Slide = ({ product }) => (
  <div className=" flex justify-center">
    <Link to={`/product/${product?.id}`} className="glide__slide">
      <img
        src={product?.colors[0]?.image_path}
        className="h-full border w-full object-fill rounded-t-2xl transition duration-200 group-hover:scale-110"
        style={{ height: 320, width: 340 }}
        alt="Slide"
      />
      <div
        style={{ height: 70, width: 340 }}
        className="flex justify-center gap-2 rounded-b-3xl bg-[#E5E1DA] p-4"
      >
        <h1 className="categorytitle text-xl tracking-tight bg-white-600 px-4 py-2 text-zinc-800 rounded-3xl">
          {product?.title}
        </h1>
      </div>
    </Link>
  </div>
);

const SlideControls = ({ direction, label, iconPath, id }) => (
  <button
    className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
    data-glide-dir={direction}
    aria-label={label}
    id={id}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5"
    >
      <title>{label}</title>
      <path strokeLinecap="round" strokeLinejoin="round" d={iconPath} />
    </svg>
  </button>
);

const CarouselControlsOutside = () => {
  const { data, isLoading } = useQuery("myData", async () => {
    // Fetch data from your API or any source
    const response = await fetch(`/api/latest-product`);
    const data = await response.json();
    return data;
  });

  return (
    <AOSComponent
      animationType="fade-up"
      duration={800}
      easing="ease-in-out"
      once={false}
    >
      <header className="text-white pt-20 ">
        <div className="max-w-7xl mx-auto relative">
          <div className="mx-auto  max-w-3xl text-center">
            <h2 className="text-3xl homepageheads   font-bold tracking-tight    text-[#C69774] md:text-5xl">
              New Arrivals
            </h2>
            <div className="flex justify-center mt-6">
              <p className="bg-[#F1E4C3] mb-20 rounded-3xl w-40 h-1 "></p>
            </div>
          </div>

          {data?.length > 0 ? (
            <>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {" "}
                  <Glider
                    draggable
                    hasArrows={true}
                    slidesToShow={3}
                    slidesToScroll={1}
                    resizeLock={true}
                    arrows={{
                      prev: "#buttonPrevNew",
                      next: "#buttonNextNew",
                    }}
                    responsive={[
                      {
                        breakpoint: 992,
                        settings: {
                          slidesToShow: 3,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 2,
                        },
                      },
                      {
                        breakpoint: 576,
                        settings: {
                          slidesToShow: 1,
                        },
                      },
                      {
                        breakpoint: 0,
                        settings: {
                          slidesToShow: 1,
                        },
                      },
                    ]}
                  >
                    {data?.map((slide, index) => (
                      <Slide key={index} product={slide} />
                    ))}
                  </Glider>
                  <div
                    className="flex w-full items-center justify-center gap-2 p-4"
                    data-glide-el="controls"
                  >
                    <SlideControls
                      direction="<"
                      label="prev slide"
                      iconPath="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                      id="buttonPrevNew"
                    />
                    <SlideControls
                      direction=">"
                      label="next slide"
                      iconPath="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      id="buttonNextNew"
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center my-4">
              <h1 className="text-3xl font-bold text-black">
                No Products Found
              </h1>
            </div>
          )}
        </div>
      </header>
    </AOSComponent>
  );
};

export default CarouselControlsOutside;
