import React from 'react';

const Hero = ({ productdData }) => {
  const data = {
    title: productdData?.title,
    description: productdData?.description,
    imageUrl: "/static/images" + productdData?.imageUrl, // Replace with your image URL
  };

  return (
    <>
      <div className='relative dark:bg-white bg-gray-50'  >
        <div className='relative'>
          <img
            className='w-full h-[44vh] object-cover'
            src={data?.imageUrl}
            alt={data?.title}
          />
          <div className='absolute top-0 left-0 w-full h-full  bg-black opacity-60'></div>
          <div className='absolute top-1/2 left-1/2 w-full px-4 lg:px-48 transform -translate-x-1/2 -translate-y-1/2 text-center text-white'>
            <h1 className='text-5xl lg:text-6xl tracking-tight homepageheads py-10'>{data?.title}</h1>
            <div className='max-w-2xl  mx-auto'>
            <p className='text-sm lg:text-lg text-justify  text-gray-300 '>{data?.description}</p>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
