import React from "react";
import { Link } from "react-router-dom";
import { imagesGallery } from "../../Data/ImagesGallery";
import AOSComponent from "../Utils/AosAnimation";

// Art Metal Wares
// Attars and Agarbattis
// Bamboo Fabric
// Embroidered and Crocheted Goods
// Jewelry Accessories
// Woodware

const Gallery = () => {
  // const heroStyles = {
  //   backgroundImage: 'url("https://img.freepik.com/free-vector/stylish-flower-line-pattern-background_361591-1162.jpg?w=1380&t=st=1701240840~exp=1701241440~hmac=d005fd2025abb6c153ff54948e74a49138b107742e5afe1604820b1278bc85b5")', // Replace with the actual path to your image
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  return (
    <AOSComponent animationType="fade-up" duration={800} easing="ease-in-out" once={false}>

    <div>
      <section className="max-w-8xl lg:pb-10  mx-auto  px-0  md:px-0">
        <div className="mx-auto    max-w-3xl text-center">
          <h2 className="text-3xl homepageheads tracking-tight    text-[#C69774] font-bold md:text-5xl">
            Product Categories
          </h2>
          <div className="flex justify-center mt-6">
            <p className="bg-[#F1E4C3] rounded-3xl w-40 h-1 "></p>
          </div>
        </div>
        <div className="flex mt-8 justify-center">
          <section className="md:p-0 grid p-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-2">
            {imagesGallery.map((item, index) => (
              <section
                key={index}
                className={`p-5 py-10 text-center transform duration-500 hover:-translate-y-2 ${
                  item.position &&
                  `-top-[65vh] ${
                    item.position === "left" ? "-left-20" : "-right-20"
                  }`
                }`}
              >
                <div>
                  <Link to={`/category/${item?.category}`}>
                    <div className="group  block overflow-hidden rounded-t-2xl  bg-gray-100">
                      <img
                        src={item?.imageUrl}
                        loading="lazy"
                        alt={item?.category}
                        style={{ height: 320, width: 340 }}
                        className="h-full w-full object-cover object-center rounded-t-2xl  transition duration-200  transform group-hover:scale-110"
                      />

                      {/* <span className="absolute left-0 top-12 rounded-r-lg bg-red-500 px-3 py-1.5 text-sm font-semibold uppercase tracking-wider text-white">
                      -50%
                    </span> */}
                    </div>
                    <div className="flex justify-center gap-2 rounded-b-3xl bg-[#E5E1DA] p-4">
                      <button className="categorytitle  text-xl tracking-tight    bg-white-600 px-4 py-2 text-zinc-800 rounded-3xl">
                        {" "}
                        {item?.category}
                      </button>
                    </div>{" "}
                  </Link>
                </div>

                {/* <div className="flex justify-center">
                  <img
                    src={item?.imageUrl}
                    className="rounded-2xl object-cover"
                    alt={item?.category}
                    style={{ height: 320, width: 340 }}
                  />
                </div>
                <Link to={`/category/${item?.category}`}>
                  <button className="py-1 catfont  relative -top-16 px-10 bg-black hover:bg-gray-500 border border-white hover:border-black text-white rounded-md">
                    {" "}
                    {item?.category}
                  </button>
                </Link> */}
              </section>
            ))}
          </section>
        </div>
      </section>
    </div>
    </AOSComponent>
  );
};

export default Gallery;
