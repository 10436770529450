import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords, additionalMetaTags }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      {additionalMetaTags &&
        additionalMetaTags?.map((metaTag, index) => (
          <meta key={index} {...metaTag} />
        ))}
    </Helmet>
  );
};

export default Meta;
