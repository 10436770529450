import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Meta from "../Components/Utils/Meta";
import Loading from "../Components/Pages/Loading";
import ProductEnquiryModal from "./ProductEnquiryModal";

const ProductDetails = () => {
  const { productId } = useParams();
  const [selectedColorItem, setSelectedColorItem] = useState(null);
  const { data, isLoading, error } = useQuery("myData", async () => {
    // Fetch data from your API or any source
    const response = await fetch(`/api/products/${productId}/`);
    const data = await response.json();
    setSelectedColorItem(data?.colors[0]);
    return data;
  });
  useEffect(() => {
    if (error) {
      toast.error(error?.message, {
        toastId: "productDetailsError",
      });
    }
  }, [error]);
  // meta tags
  const additionalMetaTags = [
    { name: "author", content: "Quantafile" },
    { property: "og:image", content: selectedColorItem?.image_path },
    { property: "og:title", content: data?.title },
    { property: "og:description", content: data?.description },
    { property: "og:url", content: window.location.href },
    { property: "og:site_name", content: "Export India Handicrafts" },
    { property: "og:type", content: "website" },
    { property: "og:locale", content: "en_US" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@exportindiatrade" },
    { name: "twitter:creator", content: "@exportindiatrade" },
    { name: "twitter:title", content: data?.title },
    { name: "twitter:description", content: data?.description },
    { name: "twitter:image", content: selectedColorItem?.image_path },
  ];
  return (
    <div className="mt-28 md:mt-0">
      {isLoading ? (
        <Loading />
      ) : (
        <div className=" " >
          <Meta
            title={`${data?.title} - Export India Handicrafts`}
            description={data?.description}
            additionalMetaTags={additionalMetaTags}
          />
          <section className=" sm:py-4">
            <div className="max-w-7xl mx-auto px-4">
              <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                <div className="lg:col-span-3 lg:row-end-1">
                  <div className="lg:flex lg:items-start">
                    <div className="lg:order-2 lg:ml-5">
                      <div className="max-w-xl overflow-hidden  ">
                        <img
                          className="lg:h-[68vh] h-full w-full border rounded-3xl   lg:w-[60vw] max-w-full object-fill"
                          src={selectedColorItem?.image_path}
                          alt={selectedColorItem?.title}
                        />
                      </div>
                    </div>

                    <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                      <div className="flex flex-row items-start lg:flex-col">
                        {data?.colors?.map((color, i) => (
                          <button
                            key={i}
                            type="button"
                            className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2  text-center"
                          >
                            <img
                              className="h-full w-full object-fill"
                              src={color?.image_path}
                              alt={color?.color}
                              onClick={() => setSelectedColorItem(color)}
                            />
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                  <div className="mb-2 md:mb-3">
                    <span className="mb-0.5 inline-block text-gray-500">
                      {data?.category?.categoryName}
                    </span>
                    <h2 className="text-2xl tracking-tight homepageheads font-bold text-gray-800 lg:text-3xl">
                      {data?.title}
                    </h2>
                  </div>
                  {data?.colors?.length > 1 && (
                    <div>
                      <span className="mb-3 mt-6 inline-block text-sm font-semibold text-gray-500 md:text-base">
                        Color
                      </span>

                      <div className="flex mt-2 flex-wrap gap-2">
                        {data?.colors?.map((color, i) => (
                          <button
                          key={i}
                            type="button"
                            title={color?.color?.name?.toLowerCase()}
                            className={`h-8 w-8 rounded-full border ring-2 ring-transparent transition duration-100 hover:ring-gray-200`}
                            onClick={() => setSelectedColorItem(color)}
                            style={{
                              backgroundColor:
                                color?.color?.value?.toLowerCase(),
                            }}
                          ></button>
                        ))}
                      </div>
                    </div>
                  )}
                  <div>
                    <span className="mb-3 mt-8 inline-block text-sm font-semibold text-gray-500 md:text-base">
                      Description
                    </span>

                    <p className="text-justify">{data?.description}</p>
                  </div>
                  <div className="mt-10">
                    <ProductEnquiryModal data={data} />
                  </div>
                  {/* <button
                    className="inline-block mt-10 flex-1 rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 sm:flex-none md:text-base"
                    onClick={(e) => setProductModalOpen(true)}
                  >
                    Enquiry
                  </button> */}
                </div>
              </div>
            </div>
          </section>
          {/* {productModalOpen ? (
            <ProductEnquiryModal
              product={data}
              onClose={() => setProductModalOpen(false)}
            />
          ) : (
            ""
          )} */}
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
