import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./Components/Pages/HomePage";
import Navbar from "./Components/Pages/Navbar";
import Footer from "./Components/Pages/Footer";
import Dashboard from "./Components/Dashboard/Dashboard";
import ProductDetails from "./Product/ProductDetails";
import NotFound from "./Components/Pages/NotFound";
import About from "./Components/Pages/About";
import ContactForm from "./Components/Pages/ContactForm";
import CategoryProducts from "./Components/Dashboard/CategoryProducts";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="bg-white">
          <Navbar />
<div className="mt-20">


          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/contact" element={<ContactForm />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/category/:category" element={<Dashboard />} />
            <Route
              exact
              path="/products/:category"
              element={<CategoryProducts />}
            />
           
            <Route path="/product/:productId" element={<ProductDetails />} />
            
            <Route path="*" element={<NotFound />} />
          </Routes>
          

        </div>
        </div>


        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
