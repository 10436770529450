export const imagesGallery=[
  {
    imageUrl: 'https://i.pinimg.com/564x/25/f5/a1/25f5a1d45d3794953d21a6c48cff6b8b.jpg',

    // imageUrl: 'https://img.freepik.com/free-photo/earthenware-bowl-with-intricate-indigenous-culture-pattern-generative-ai_188544-40976.jpg?t=st=1701201871~exp=1701205471~hmac=564487921f77ae384cdf0ca8bedac5680ab24725feed0bea229ede6c91b70645&w=1380',
    category: 'Exquisite Furniture',
    link:"woodware",
    position: 'left',
  },
  {
    imageUrl: 'https://i.pinimg.com/564x/73/d3/08/73d308d989275546aeb7233eb2ce40ab.jpg',

    // imageUrl: 'https://img.freepik.com/free-photo/earthenware-bowl-with-intricate-indigenous-culture-pattern-generative-ai_188544-40976.jpg?t=st=1701201871~exp=1701205471~hmac=564487921f77ae384cdf0ca8bedac5680ab24725feed0bea229ede6c91b70645&w=1380',
    category: 'Spiritual Sculptures',
    link:"woodware",
    position: 'left',
  },
  
  {
    imageUrl: 'https://i.pinimg.com/564x/87/2e/a1/872ea10822e9a2b4b7bb40cc8a6061d4.jpg',
    category: 'Designer Jewellery',
    link:"jewellery",
    position: 'left',
  },
    {
      
    imageUrl: '/static/images/metalcategory.jpg',
      category: 'Metalcraft',
      link:'artMetals',
      position: 'left',
    },
    {
      imageUrl: 'https://i.pinimg.com/564x/7b/18/2d/7b182dc74a4dbdfe0f5e74a0964b3d4d.jpg',

      // imageUrl: 'https://img.freepik.com/free-photo/earthenware-bowl-with-intricate-indigenous-culture-pattern-generative-ai_188544-40976.jpg?t=st=1701201871~exp=1701205471~hmac=564487921f77ae384cdf0ca8bedac5680ab24725feed0bea229ede6c91b70645&w=1380',
      category: 'Woodcraft',
      link:"woodware",
      position: 'left',
    },
    {
      imageUrl: 'https://i.pinimg.com/564x/df/14/9c/df149c6fec7363a7c3eb5a224c7e916a.jpg',
      category: 'Incense Sticks',
      link:'insence',
      position: 'right',
    },
    
    {
      imageUrl: '/static/images/textilecategory.jpeg',
      category: 'Textile',
      link:"embroidered",
      position: 'none',
    },
    // {
    //   imageUrl: 'https://img.freepik.com/free-photo/oxide-steel-texture_1194-8558.jpg?w=740&t=st=1701201812~exp=1701202412~hmac=a12fa365df7743e9dd2ff5e434cff3391b6de02241a8315fdc04bdf33692e344',
    //   category: 'Bamboo Fabric',
    //   position: 'left',
    // },
    
    
    {
      imageUrl: 'https://i.pinimg.com/564x/77/40/90/7740906f62af8bfcaac3568f987a07ad.jpg',

      // imageUrl: 'https://img.freepik.com/free-photo/earthenware-bowl-with-intricate-indigenous-culture-pattern-generative-ai_188544-40976.jpg?t=st=1701201871~exp=1701205471~hmac=564487921f77ae384cdf0ca8bedac5680ab24725feed0bea229ede6c91b70645&w=1380',
      category: 'Needlework',
      link:"woodware",
      position: 'left',
    },
    {
      imageUrl: 'https://i.pinimg.com/564x/b2/0b/5f/b20b5fb037789a9de5fc31a928f3efe6.jpg',
      category: 'Canecraft',
      link:"bambooFabric",
      position: 'right',
    },
    
  ]