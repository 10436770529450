import React from "react";
import AOSComponent from "../Utils/AosAnimation";

const OurPrinciples = () => {
  const heroStyles = {
    backgroundImage:
      'url("https://img.freepik.com/free-vector/decorative-golden-mandala-red-background_1035-25425.jpg?w=740&t=st=1704209056~exp=1704209656~hmac=06f35a0ae2b4ea9fd9c23572e144594ab1035b925f60cc2344d9dfcefc78a4ed")', // Replace with the actual path to your image
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
        <AOSComponent animationType="fade-up" duration={800} easing="ease-in-out" once={false}>

      <section>
        <div className="mx-auto w-full lg:max-w-[80%] px-5 py-16">
          <div className="mx-auto   md:mb-12  lg:mb-8 max-w-3xl text-center">
            <h2 className="text-3xl homepageheads tracking-tight  font-bold text-[#C69774] md:text-5xl">
              Our Principles
            </h2>
            <div className="flex justify-center mt-6">
            <p className="bg-[#F1E4C3] rounded-3xl w-40 h-1 "></p>
          </div>
          </div>
          <section>
            <div className="px-2 pt-6 mx-auto space-y-24 md:px-12 lg:px-32 max-w-8xl">
              <div className="grid items-center grid-cols-1 gap-4 mt-6 list-none lg:grid-cols-2 lg:gap-12">
                <div>
                  <p className="mt-8 homepageheads  text-3xl lg:text-4xl font-semibold tracking-tight text-[#4F6F52]">
                    Skill
                  </p>
                  <p className="mt-4 text-sm lg:text-base text-gray-500">
                    Unveiling the hidden skills of rural India. Our mission is
                    to showcase the incredible talent and creativity of rural
                    artisans, bringing their uniqueness to the forefront for the
                    world to appreciate.
                  </p>
                </div>
                <div className="p-2 border bg-gray-50 rounded-3xl lg:order-first">
                  <img
                    alt="Lexingtøn thumbnail"
                    className="h-80 w-full rounded-2xl object-cover drop-shadow-2xl"
                    src="https://i.pinimg.com/564x/6c/b9/2d/6cb92d2b6be14a6165696235c95fad48.jpg"
                  />
                </div>
              </div>
              <div className="grid items-center grid-cols-1 gap-4 mt-2 list-none lg:grid-cols-2 lg:gap-12">
                <div>
                  <p className="mt-2 homepageheads text-3xl lg:text-4xl font-semibold tracking-tight text-[#4F6F52]">
                    Sustainability
                  </p>
                  <p className="mt-4 text-sm lg:text-base text-gray-500">
                    At Export India, we are passionately committed to
                    sustainability. From eco-friendly practices to responsible
                    sourcing, we strive to minimize our environmental impact,
                    ensuring a brighter and greener future for generations to
                    come.
                  </p>
                </div>
                <div className="p-2 border bg-gray-50 rounded-3xl lg:order-first">
                  <img
                    alt="Lexingtøn thumbnail"
                    className="h-80 w-full rounded-2xl object-cover drop-shadow-2xl"
                    src="https://i.pinimg.com/564x/05/58/b1/0558b1ca92a5d5f7e42bd2c9bf19b4ec.jpg"
                  />
                </div>
              </div>
              <div className="grid items-center grid-cols-1 gap-4 mt-2 list-none lg:grid-cols-2 lg:gap-12">
                <div>
                  <p className="mt-2 homepageheads text-3xl lg:text-4xl font-semibold tracking-tight text-[#4F6F52]">
                    Success
                  </p>
                  <p className="mt-4 text-sm lg:text-base text-gray-500">
                    Dedicated to top-notch quality and on-time deliveries, our
                    commitment to excellence underscores every product. We
                    prioritize customer satisfaction, ensuring a blend of
                    superior craftsmanship and reliable service. Our success
                    lies in bringing happiness to the lives of rural India.
                  </p>
                </div>
                <div className="p-2 border bg-gray-50 rounded-3xl lg:order-first">
                  <img
                    alt="Lexingtøn thumbnail"
                    className="h-80 w-full rounded-2xl object-cover drop-shadow-2xl"
                    src="https://images.unsplash.com/photo-1604087267213-40e35f1719a3?q=80&w=1956&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      </AOSComponent>
    </>
  );
};

export default OurPrinciples;
