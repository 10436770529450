import React, { useState } from "react";
import MultipleEnquiry from "./MultipleEnquiry";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { getCsrfToken } from "../utils/getCsrfToken";
import Acknowledgement from "../Components/Utils/Acknowledgement";

const TestEnq = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isformSubmitted, setIsformSubmitted] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    jewellery: false,
    metalcraft: false,
    IncenseSticks: false,
    canecraft: false,
    textile: false,
    woodcraft: false,
    spiritualScupltures: false,
    needlework: false,
    ExquisiteFurniture: false,
  });

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [checkboxName]: !prevValues[checkboxName],
    }));
  };

  const [formData, setFormData] = useState({
    product: `${data?.id}`,
    name: "",
    email: "",
    mobile: "", // Assuming you have a mobile field in your form
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let newErrors = {};

    // Perform basic input validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile is required";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);

    // Return true if there are no errors, indicating the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form before submitting
    if (validateForm()) {
      // Your form submission logic here

      // For demonstration purposes, you can log the form data
      try {
        setIsLoading(true);
        const selectedCheckboxValues = Object.keys(checkboxValues).filter(
          (checkboxName) => checkboxValues[checkboxName]
        
        );
        const response = await fetch("/api/enquiry/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            product: formData.product,
            message: formData.message,
            mobile: formData.mobile,
            more_categories: selectedCheckboxValues,
          }),
        });

        if (!response.ok) {
          setIsLoading(false);
          toast.error("Failed to submit form", {
            toastId: "failed_to_submit",
          });
        } else {
          toast.success("Message sent successfully", {
            toastId: "message_sent",
          });
          setIsformSubmitted(true);
          return response.json();
        }
      } catch (error) {
        toast.error("Failed to submit form", {
          toastId: "failed_to_submit_error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Close the modal
  const closeModal = () => {
    document.getElementById("my_modal_4").close();
  };
  return (
    <div>
      <button
        className="btn bg-[#B19470] border border-[#B19470]  hover:bg-[#B2A59B] hover:border-[#B2A59B] tracking-tight text-white"
        onClick={() => document.getElementById("my_modal_4").showModal()}
      >
        Send Enquiry
      </button>

      <dialog id="my_modal_4" className="modal backdrop-blur">
        <div className="modal-box dark:bg-white scrollbar-hide w-11/12 max-w-5xl">
          <div className="bg-[#FBF9F1] border border-[#F1E4C3] rounded-xl w-full h-fit py-2">
            <h1 className="text-center dark:text-zinc-700 text-xl homepageheads font-bold tracking-tight">
              Enquiry on{" "}
            </h1>
            <p className="text-center mt-2 text-lg text-[#C6A969] tracking-tight lg:text-xl font-bold">
              {`${data?.title}`}
            </p>
          </div>

          {isformSubmitted ? (
            <Acknowledgement />
          ) : (
            <div className="grid overflow-y-auto h-[55vh] scrollbar-hide gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
              <div className="flex lg:border-r border-zinc-100 flex-wrap mt-4 justify-center">
                <div className="grid gap-4 w-full max-w-md grid-cols-1">
                  <label htmlFor="name" className="form-control mt-4">
                    <div className="label">
                      <span className="label-text dark:text-zinc-600">
                        Your name
                      </span>
                    </div>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter name here"
                      className={`input dark:bg-white dark:border-slate-300 input-bordered ${
                        errors.name ? "border-red-500" : ""
                      }`}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                    )}
                  </label>
                  <label htmlFor="email" className="form-control -mt-6">
                    <div className="label">
                      <span className="label-text dark:text-zinc-600">
                        Your email
                      </span>
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter email here"
                      className={`input dark:bg-white dark:border-slate-300 input-bordered ${
                        errors.email ? "border-red-500" : ""
                      }`}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email}
                      </p>
                    )}
                  </label>
                  <label className="form-control -mt-6 w-full">
                    <div className="label">
                      <span className="label-text text-zinc-700 ">Mobile</span>
                    </div>
                    <PhoneInput
                      international={false}
                      defaultCountry="IN"
                      value={formData.mobile}
                      className={`input input-bordered text-gray-600 dark:bg-gray-50 w-full ${
                        errors.mobile ? "border-red-500" : ""
                      }`}
                      onChange={(mobile) =>
                        setFormData({ ...formData, mobile: mobile })
                      }
                      numberInputProps={{
                        className: "pl-2 dark:bg-gray-50 text-gray-600",
                      }}
                      countrySelectProps={{
                        className: "pl-2 dark:bg-gray-50 text-gray-600",
                      }}
                    />
                    {errors.mobile && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.mobile}
                      </p>
                    )}
                  </label>
                  {/* <CustomSelect
                  selectedCountry={formData.country}
                  onChange={(country) => setFormData({ ...formData, country })}
                  error={errors.country}
                /> */}
                  {errors.country && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.country}
                    </p>
                  )}
                  <label htmlFor="message" className="form-control -mt-6">
                    <div className="label">
                      <span className="label-text dark:text-zinc-600">
                        Your message
                      </span>
                    </div>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className={`textarea dark:bg-white border dark:border-slate-300 textarea-bordered h-24 ${
                        errors.message ? "border-red-500" : ""
                      }`}
                      placeholder="Enter message here"
                    ></textarea>
                    {errors.message && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.message}
                      </p>
                    )}
                  </label>
                </div>
              </div>

              <MultipleEnquiry
                checkboxValues={checkboxValues}
                handleCheckboxChange={handleCheckboxChange}
              />
            </div>
          )}

 
          <div className="flex justify-center items-center gap-4">
            <div className="modal-action  ">
              <button
                onClick={closeModal}
                className="btn btn-neutral px-10 dark:text-zinc-100"
              >
                Close
              </button>
            </div>
            {!isformSubmitted && <div className="relative top-3">
              <button
                className="btn bg-[#279EFF] border border-[#279EFF] hover:border-[#96B6C5] hover:bg-[#96B6C5] text-white px-10"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            </div>}
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default TestEnq;
