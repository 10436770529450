import React from "react";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import Sent from "../Pages/Animation/Sent.json"
const Acknowledgement = () => {
    return (
        <div>
            <div className="bg-white max-w-4xl mx-auto p-4 ">
                <div className="bg-white w-fit border-4 rounded-3xl p-6  md:mx-auto">
                    <div className="flex justify-center pt-10">
                   
                        <Lottie className="w-24 h-24"  animationData={Sent} />
                    </div>
                    <div className="text-center mt-4">
                        <h3 className="md:text-2xl text-base homepageheads tracking-tight text-gray-900 font-semibold text-center">
                            Your message has been sent !
                        </h3>
                        <p className="text-gray-600  my-6">
                            Thank you for contacting us. We will get back to you soon.
                        </p>
                        <p className="mt-6"> Have a great day ! </p>
                        <div className="py-10 text-center">
                            <Link to="/"

                                className="px-12 rounded-3xl bg-blue-600 hover:bg-indigo-500 text-white font-semibold py-3"
                            >
                                Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Acknowledgement;
