import React from "react";
import Gallery from "./Gallery";
import Hero from "./Hero";
import WhoWeAre from "./WhoWeAre";
import OurPrinciples from "./OurPrinciples";
import Meta from "../Utils/Meta";
import Test from "./Test";
const HomePage = () => {

  
    
  
  return (
    <div>
      <Meta title="Export India Handicrafts - Bringing Indian Handicrafts to the World" />
      <Hero />
      <div >
      <WhoWeAre />
      <Gallery />
      <OurPrinciples />
      </div>
      <Test/>
    </div>
  );
};

export default HomePage;
