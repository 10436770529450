import React, { useState } from "react";
import { Link } from "react-router-dom";
import { navLinks } from "../../Data/navLinks";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav
      className={`bg-white fixed  py-2 z-50  top-0  w-full border-b border-gray-200  ${
        isMobileMenuOpen ? "md:hidden" : ""
      }`}
    >
      <div className=" flex  items-center px-6 justify-between  p-4">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse mr-7"
        >
          <img
            
            src="/static/images/art.png"
            alt="Export India"
            className="w-24"
          />
        </Link>

        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <Link
            to={"/contact"}
            type="button"
            className="text-white border border-[#B19470]  bg-[#B19470]   font-semibold tracking-tight rounded-lg text-sm px-4 py-2 ml-5 break-words text-center"
          >
            Contact us
          </Link>
          <button
            onClick={toggleMobileMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="navbar-sticky"
            aria-expanded={isMobileMenuOpen}
          >
            <span className="sr-only">
              {isMobileMenuOpen ? "Close menu" : "Open main menu"}
            </span>
            {isMobileMenuOpen ? (
              // Close icon when the mobile menu is open
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            ) : (
              // Menu icon when the mobile menu is closed
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            )}
          </button>
        </div>

        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex text-[16px]  font-medium   flex-col md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
            {navLinks.map((link) => (
              <li key={link?.title}>
                <Link
                  to={link?.link}
                  className="block py-2 px text-zinc-900 rounded md:bg-transparent   md:p-0 "
                  aria-current="page"
                >
                  {link?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="md:hidden w-full bg-gray-100">
          <ul className="p-4">
            <li>
              <Link
                href="/"
                className="block py-2 px-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent  hover:text-gray-900  md:p-0    "
              >
                Home
              </Link>
            </li>
            {navLinks.map((link) => (
              <li key={link?.title}>
                <Link
                  to={link?.link}
                  className="block py-2 px-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent  md:p-0  hover:text-gray-900 "
                >
                  {link?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
