import React from "react";
import { navLinks } from "../../Data/navLinks";
import { Link } from "react-router-dom";

const FootTest = () => {
  return (
    <div className="relative">
      <footer className=" bg-gray-50">
        <div className=" space-y-8 px-6 py-10 text-gray-600 md:px-12 lg:px-20">
          <div className="grid grid-cols-2    items-center gap-4 border-b pb-8">
            <div className="flex justify-start">
              <img
                src="/static/images/art.png"
                alt="Export India"
                className="w-28"
              />
            </div>
            <div className="flex items-center gap-6 justify-end">
              <Link
                to="https://www.linkedin.com/company/export-india-handicrafts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6"
                  src="https://www.svgrepo.com/download/475661/linkedin-color.svg"
                  alt="linkedin"
                />
              </Link>
              <Link
                to="https://www.instagram.com/exportindiahandicrafts?igsh=eWhxZDRraTlkbXMx&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6"
                  src="https://www.svgrepo.com/show/303154/instagram-2016-logo.svg"
                  alt="instagram"
                />
              </Link>
              <Link
                to="https://www.facebook.com/profile.php?id=61555496814665"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-8 h-8"
                  src="https://www.svgrepo.com/show/448224/facebook.svg"
                  alt="facebook"
                />
              </Link>
              <Link
                to="https://api.whatsapp.com/send?phone=919004683385"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-8 h-8"
                  src="https://www.svgrepo.com/show/382712/whatsapp-whats-app.svg"
                  alt="whatsapp"
                />
              </Link>
            </div>
          </div>
          <div
            role="list"
            className="flex flex-col items-center justify-center gap-4 py-2 sm:flex-row sm:gap-8"
          >
            {navLinks.map((link) => (
              <Link
                to={link?.link}
                className="inline-block  py-2   transition hover:text-[#d6a701]"
                key={link?.title}
              >
                {link?.title}
              </Link>
            ))}
          </div>

          <div className="flex border-t-2 max-w-xs mx-auto gap-6 items-center justify-center">
            <Link
              to="/contact"
              className="inline-block px-6 py-2   transition hover:text-[#d6a701]"
            >
              Contact us
            </Link>
          </div>
          <div className="flex  justify-center items-center rounded-md bg-gray-200 px-4 py-3 text-gray-600">
            <span>
              &copy; Export India 2023. All rights reserved.{" "}
              <span id="year"></span>
            </span>{" "}
          </div>
          <span className="text-white absolute left-0 right-0 text-center bottom-0">
            Designed by{" "}
            <Link
              to="https://www.quantafile.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Quantafile.
            </Link>{" "}
          </span>
        </div>
      </footer>
    </div>
  );
};

export default FootTest;
