import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import Acknowledgement from "../Utils/Acknowledgement";
import { getCsrfToken } from "../../utils/getCsrfToken";

const ContactForm = () => {
  const [isformSubmitted, setIsformSubmitted] = useState(false);
  const [loading , setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile is required";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);

    // Return true if there are no errors, indicating the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form before submitting
    if (validateForm()) {
      try {
        setLoading(true);


     
      const response = await fetch("/api/contact/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        return toast.error("Something went wrong", {
          toastId: "contact-form-error",
        });
      }
      toast.success("Message sent successfully", {
        toastId: "contact-form-success",
      });
      setIsformSubmitted(true);
    }
  
   catch (error) {
    toast.error("Something went wrong", {
      toastId: "contact-form-error",
    });
  } finally {
    setLoading(false);
  }
    }

  };

  return (
    <div className="p-4 lg:p-8 bg-[#f2f4f6] flex items-center">
      <section className="flex items-center justify-center w-full">
        <div className="mx-auto w-full max-w-7xl  md:px-10">
          <div className="flex justify-center items-center gap-8 sm:gap-20 lg:grid-cols-2">
            <div className="mx-auto rounded-3xl w-full lg:w-[40vw] h-auto bg-white p-8 text-center">
              {isformSubmitted ? (
                <Acknowledgement />
              ) : (
                <div>
                  <h3 className="text-2xl text-zinc-800 font-bold md:text-3xl homepageheads tracking-tight">
                    Contact us
                  </h3>
                  <p className="mx-auto mb-6 mt-4 max-w-md text-sm tracking-tight text-zinc-800 ">
                    Reach out to us — your questions, feedback, and inquiries
                    matter. We're here to assist you every step of the way!
                  </p>
                  <p className="mx-auto mb-6 mt-4 max-w-md text-sm tracking-tight text-zinc-800 ">
                    Or Connect through <br />
                    Email: sales@exportindiahandicrafts.com <br />
                    Phone: +91 9004683385
                  </p>

                  <form
                    className="mx-auto mb-4 max-w-xl text-left"
                    name="wf-form-password"
                    onSubmit={handleSubmit}
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <label className="form-control w-full">
                        <div className="label">
                          <span className="label-text text-zinc-700 ">
                            Your name
                          </span>
                        </div>
                        <input
                          type="text"
                          name="name"
                          placeholder="Type here"
                          className={`input input-bordered dark:bg-gray-50 text-gray-600 w-full ${
                            errors.name ? "border-red-500" : ""
                          }`}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.name}
                          </p>
                        )}
                      </label>
                      <label className="form-control w-full">
                        <div className="label">
                          <span className="label-text text-zinc-700 ">
                            Your Email
                          </span>
                        </div>
                        <input
                          type="text"
                          name="email"
                          placeholder="Type here"
                          className={`input input-bordered text-gray-600 dark:bg-gray-50 w-full ${
                            errors.email ? "border-red-500" : ""
                          }`}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.email}
                          </p>
                        )}
                      </label>
                    </div>
                    <label className="form-control mt-2 w-full">
                      <div className="label">
                        <span className="label-text text-zinc-700 ">
                          Mobile
                        </span>
                      </div>
                      <PhoneInput
                        international={false}
                        defaultCountry="IN"
                        value={formData.mobile}
                        className={`input input-bordered text-gray-600 dark:bg-gray-50 w-full ${
                          errors.mobile ? "border-red-500" : ""
                        }`}
                        onChange={(mobile) =>
                          setFormData({ ...formData, mobile: mobile })
                        }
                        numberInputProps={{
                          className: "pl-2 dark:bg-gray-50 text-gray-600",
                        }}
                        countrySelectProps={{
                          className: "pl-2 dark:bg-gray-50 text-gray-600",
                        }}
                      />
                      {errors.mobile && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.mobile}
                        </p>
                      )}
                    </label>
                    <label className="form-control mt-2">
                      <div className="label">
                        <span className="label-text text-zinc-600">
                          Your message
                        </span>
                      </div>
                      <textarea
                        name="message"
                        className={`textarea dark:bg-gray-50 textarea-bordered h-24 text-gray-600 ${
                          errors.message ? "border-red-500" : ""
                        }`}
                        placeholder="Enter message here"
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.message}
                        </p>
                      )}
                    </label>
                    
                    <button disabled={loading} className="btn  bg-indigo-600 hover:bg-indigo-500 inline-block mt-6 w-full cursor-pointer px-6 py-4 text-center font-semibold text-white transition">
                    {loading ? "Sending..." : "Send Message"}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
