/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Product from "./Product";
import Loading from "../Pages/Loading";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Meta from "../Utils/Meta";
import { DashboardDetails } from "../../Data/DashboardDetails";

const CategoryProducts = () => {
  const { category } = useParams();
  const {
    data: products,
    isLoading: Prodloading,
    error: allProductsError,
  } = useQuery("getallproducts", async () => {
    // Fetch data from your API or any source
    const response = await fetch(`/api/all-products/category/${category}/`);
    const data = await response.json();
    return data;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loaderRef = useRef(null);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !loading) {
      loadMoreData();
    }
  };

  const loadMoreData = () => {
    setLoading(true);

    // Simulating API call for more data, adjust as needed
    // In a real-world scenario, you would fetch more data using an API
    setTimeout(() => {
      // Assuming nextPageData is the additional data to be loaded
      const nextPageData = /* Fetch more data */ [];

      if (nextPageData.length > 0) {
        // Append the new data to your existing products array
        // Adjust your logic based on your data structure
        // Assuming nextPageData has a nextPage property indicating if there's more data
        // Adjust this condition based on your actual data structure
        setHasMore(nextPageData.some((item) => item.nextPage));
        setCurrentPage(currentPage + 1);
        // Update your state with the new products
        // setProducts(newProducts);
      } else {
        setHasMore(false);
      }

      setLoading(false);
    }, 1000); // Simulated delay, replace with actual API call
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });

    const currentLoader = loaderRef.current;
    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => observer.disconnect();
  }, [hasMore, loading]);
  useEffect(() => {
    if (allProductsError) {
      toast.error(allProductsError, {
        toastId: "error",
      });
    }
  }, [allProductsError]);
  return (
    <div className="pt-2">
      {Prodloading ? (
        <Loading />
      ) : (
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <Meta
            title={`${DashboardDetails[category]?.title} - Export India Handicrafts`}
            description={DashboardDetails[category]?.description}
          />
          <div className="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4 mt-5">
            {products?.length
              ? products?.map((product, index) => (
                  <Link key={index} to={`/product/${product?.id}`}>
                    <Product
                      id={product?.id}
                      title={product?.title}
                      imageUrl={product?.image_path}
                    />
                  </Link>
                ))
              : ""}
          </div>
          <div ref={loaderRef} className="text-center pt-20 pb-3">
            {!hasMore && <p>No more items to load</p>}
            {loading && <Loading />}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryProducts;
