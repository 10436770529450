import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../Pages/Loading";
import { useQuery } from "react-query";
import Hero from "./Hero";
import { fetchData } from "../../actions/fetchProducts";
import { DashboardDetails } from "../../Data/DashboardDetails";
import Meta from "../Utils/Meta";
import Glider from 'react-glider';
import 'glider-js/glider.min.css';
const Slide = ({ product }) => (
  <div className=" flex justify-center mb-3">
    <Link
          to={`/product/${product?.id}`}>
      <img
        src={product?.colors[0]?.image_path}
        className="h-full w-full object-fill border rounded-t-2xl transition duration-200 group-hover:scale-110"
        style={{ height: 320, width: 340 }}
        alt="Slide"
      />
      <div
        style={{ height: 70, width: 340 }}
        className="flex justify-center gap-2 rounded-b-3xl bg-[#E5E1DA] p-4"
      >
        <h1
          className="categorytitle text-xl tracking-tight bg-white-600 px-4 py-2 text-zinc-800 rounded-3xl"
        >
          {product?.title}
        </h1>
      </div>
    </Link>
  </div>
);

const SlideControls = ({ direction, label, iconPath, id }) => (
  <button
    className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
    // data-glide-dir={direction}
    aria-label={label}
    id={id}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5"
    >
      <title>{label}</title>
      <path strokeLinecap="round" strokeLinejoin="round" d={iconPath} />
    </svg>
  </button>
);

const Dashboard = () => {
  const { category } = useParams();
  const { data, isLoading, error } = useQuery(["myData", category], () =>
    fetchData(category)
  );


  useEffect(() => {


    // Check if the data is loaded before initializing Glide
    if (!isLoading && !error && data?.length > 0) {
      // initializeGlide();
    }
  }, [isLoading, error, data]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Meta
            title={`${DashboardDetails[category]?.title} - Export India Handicrafts`}
            description={DashboardDetails[category]?.description}
          />
          <Hero productdData={DashboardDetails[category]} />
          <header className="pt-20 text-black">
            {data?.length > 0 ? (
              <>
                <Glider
                  draggable
                  hasArrows={true}
                  slidesToShow={3}
                  slidesToScroll={1}
                  resizeLock={true}
                  arrows={{
                    prev: '#buttonPrev',
                    next: '#buttonNext',
                  }}
                  responsive={[
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                    {
                      breakpoint: 0,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >

                  {data?.map((slide, index) => (
                    <Slide key={index} product={slide} />
                  ))}
                </Glider>
                <div
                  className="flex w-full items-center justify-center gap-2 p-4"
                  data-glide-el="controls"
                >
                  <SlideControls
                    direction="<"
                    label="prev slide"
                    iconPath="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                    id="buttonPrev"
                  />
                  <SlideControls
                    direction=">"
                    label="next slide"
                    iconPath="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    id="buttonNext"
                  />
                </div>
                <div className="flex pb-10 items-center justify-center">
                  <Link
                    to={`/products/${category}`}
                    className="bg-[#B2A59B] transition duration-500 hover:opacity-80 font-bold border border-gray-200 rounded-3xl text-white b px-10 text-md my-3 py-3"
                  >
                    Show All
                  </Link>
                </div>
                {/* <div className="border text-black">
                  <button id="buttonPrev" className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"> <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5"
                  /></button>
                  <button id="buttonNext">Next</button>
                </div> */}

              </>

              // <div className="max-w-7xl mx-auto relative">
              //   <div className="glide-04 py-10">
              //     <div
              //       className=""
              //       data-glide-el="track"
              //     >
              //       <ul className="glide__slides flex justify-center w-full">
              //         {data?.map((slide, index) => (
              //           <Slide key={index} product={slide} />
              //         ))}
              //       </ul>
              //     </div>
              //     <div
              //       className="flex w-full items-center justify-center gap-2 p-4"
              //       data-glide-el="controls"
              //     >
              //       <SlideControls
              //         direction="<"
              //         label="prev slide"
              //         iconPath="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              //       />
              //       <SlideControls
              //         direction=">"
              //         label="next slide"
              //         iconPath="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              //       />
              //     </div>
              //   </div>
              //   {
              //     <>
              //     
              //     </>
              //   }
              // </div>

            ) : (
              <div className="flex justify-center items-center py-4">
                <h1 className="text-3xl font-bold text-black">
                  No Products Found
                </h1>
              </div>
            )}
          </header>
        </>
      )}
    </>
  );
};

export default Dashboard;
