import { toast } from "react-toastify";

  // Function to fetch data
  export const fetchData = async (category) => {
    try {
      // Fetch data from your API or any source
      const response = await fetch(`/api/products/category/${category}/`);
      const data = await response.json();
      return data?.slice(0, 6);
    } catch (error) {
      // Handle errors, you might want to set an error state here
      toast.error("No Products found.", {
        toastId: "error",
      });
    }
  };