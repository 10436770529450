import React from 'react'
import Meta from '../Utils/Meta'
import { Link } from 'react-router-dom'
const NotFound = () => {
  return (
    <>
    <Meta title='404 Not Found - Export India Handicrafts' />
    

    <main class="relative h-screen overflow-hidden bg-white">
    
    <div class="container z-10 flex items-center justify-between h-screen px-6 pt-32 mx-auto md:pt-0">
        <div class="container relative flex flex-col-reverse items-center justify-between px-6 mx-auto lg:flex-row">
            <div class="w-full mb-16 text-center md:mb-8 lg:text-left">
                <h1 class="mt-12 font-sans text-5xl tracking-tight text-center font-semibold text-gray-700 lg:text-left lg:text-8xl md:mt-0">
                    Sorry, this page isn&#x27;t available
                </h1>
               <Link to="/"> <button class="px-2 py-2 mt-16 text-lg font-light text-white transition duration-200 ease-in rounded-2xl bg-slate-700 border border-gray-100 w-36 hover:bg-indigo-400 focus:outline-none">
                    Go back home
                </button></Link>
            </div>
            <div class="relative block x w-full max-w-md mx-auto md:mt-0 lg:max-w-2xl">
                <img className='rounded-2xl' src="https://img.freepik.com/free-vector/oops-404-error-with-broken-robot-concept-illustration_114360-5529.jpg?w=740&t=st=1704712828~exp=1704713428~hmac=b2696a28ae5fffdfbcadc28ba9ecf93dae44bbbdc6e00bcc7126545530138dcb"/>
            </div>
        </div>
    </div>
</main>

    </>
  )
}

export default NotFound