export const navLinks = [
    // {
    //   title: "Home",
    //   link: "/",
    // },
    {
      title: "Exquisite Furniture",
      link: "/category/Exquisite Furniture",
    },
    {
      title: "Spiritual Sculptures",
      link: "/category/Spiritual Sculptures",
    },
    {
      title: "Designer Jewellery",
      link: "/category/Designer Jewellery",
    },
    {
      title: "Metalcraft",
      link: "/category/Metalcraft",
    },
    {
      title: "Woodcraft",
      link: "/category/Woodcraft",
    },
    {
      title: "Incense Sticks",
      link: "/category/Incense Sticks",
    },
    
    {
      title: "Textile",
      link: "/category/Textile",
    },
    
    
    {
      title: "Needlework",
      link: "/category/Needlework",
    },
    {
      title: "Canecraft",
      link: "/category/Canecraft",
    },
    
    // {
    //   title: "About Us",
    //   link: "/about",
    // },
    // {
    //   title: "Contact Us",
    //   link: "/contact",
    // },
  ]