import React from "react";
import { Link } from "react-router-dom";

const Product = ({ id, imageUrl, title }) => {
  return (
    <div >

      <div  >
        <Link
          to={`/product/${id}`}
          className="group relative block h-96  overflow-hidden rounded-t-xl bg-gray-100"
        >
          <img
            src={`${imageUrl}`}
            loading="lazy"
            alt={`${title}`}
            className="h-full w-full border-2 object-fill rounded-t-xl object-center transition duration-200 group-hover:scale-110"
          />
        </Link>

        <div className="flex items-start justify-center gap-2 rounded-b-lg bg-gray-100 p-4">
          <div className="flex flex-col">
            <Link
              to={`/product/${id}`}
              className="font-bold text-gray-800 text-center transition duration-100 hover:text-gray-500 lg:text-lg"
            >
              {title}
            </Link>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
