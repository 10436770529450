import React, { useState, useEffect } from "react";
import AOSComponent from "../Utils/AosAnimation";

const slides = [
  {
    id: "slide1",
    largeImageUrl: "/static/images/heroslider2.png",
    mobileImageUrl: "/static/images/mobileslide1.png",
    title: "Export India",
    description: "Bringing India to the World",
    url: "/category/Art%20Metal%20Wares",
  },
  {
    id: "slide4",
    largeImageUrl: "/static/images/heroslider1.png",
    mobileImageUrl: "/static/images/mobileslide2.png",
    title: "Export India",
    description: "Bringing India to the World",
    url: "/category/Woodware",
  },
  {
    id: "slide5",
    largeImageUrl: "/static/images/heroslider3.png",
    mobileImageUrl: "/static/images/mobileslide3.png",
    title: "Export India",
    description: "Bringing India to the World",
    url: "/category/Attars%20and%20Agarbattis",
  },
];

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(interval);
  }, []);

  // Check if the device screen width is less than or equal to 768 pixels (adjust as needed)
  const isMobile = window.innerWidth <= 768;

  return (
    
    <div className="mt-4">
      <div className="fade-container">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`fade-item ${
              index === currentSlide ? "active" : "hidden"
            }`}
          >
            <div
              className="bg-cover bg-center h-screen w-full transition-opacity ease-in-out duration-1000"
              style={{
                backgroundImage: `url(${
                  isMobile ? slide.mobileImageUrl : slide.largeImageUrl
                })`,
              }}
            >
              <div className="text-black text-center mt-10">
                <div className="flex justify-center">
                  <img
                    src="/static/images/art.png"
                    alt="logo tailus"
                    className="w-60"
                  />
                </div>
                <p className="text-lg mt-4">{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
